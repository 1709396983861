import "dayjs/locale/ja";
import LayoutProvider from "./common/context/LayoutContext";
import Loader from "./components/LazyLoading/Loader";
import PrimaryLayout from "./common/context/PrimaryLayout";
import PublicLayout from "./common/context/PublicLayout";
import SpinBase from "./components/SpinBase";
import dayjs from "dayjs";
import jaJP from "antd/locale/ja_JP";
import usePermissions from "./common/hooks/usePermissions";
import { ConfigProvider } from "antd";
import { Suspense, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useRoutes } from "react-router-dom";
import { api } from "./common/redux/api";
import { GetUserMeApiResponse, useLazyGetUserMeQuery } from "./common/redux/endpoints/auth";
import { UseMutationError } from "./common/redux/endpoints/type";
import { updateAccessToken, updateUserProfile } from "./common/redux/slices/auth.slice";
import { AppDispatch, RootState } from "./common/redux/store";
import { routeConfig } from "./common/routes/routeConfig";

dayjs.locale("ja");
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-console */

// import React, { lazy } from "react";

// import LoadAble from "./components/LazyLoading/LoadAble";

// const MainPage = LoadAble(lazy(() => import("./pages/MainPage")));
// const CreateProject = LoadAble(lazy(() => import("./pages/CreateProject")));
// const AssetGuarantee = LoadAble(lazy(() => import("./pages/AssetGuarantee")));
// const PersonalGuarantee = LoadAble(lazy(() => import("./pages/PersonalGuarantee")));
// const CashGuarantee = LoadAble(lazy(() => import("./pages/CashGuarantee")));
// const ExportPDF = LoadAble(lazy(() => import("./pages/ExportPDF")));
// const ExportPdfExample = LoadAble(lazy(() => import("./pages/ExportPdfExample")));
// const PseudoLogin = LoadAble(lazy(() => import("./pages/PseudoLogin")));
// const LoginPage = LoadAble(lazy(() => import("./pages/LoginPage")));
// const TemplatePdf = LoadAble(lazy(() => import("./pages/ExportPDF/TemplatePDF")));

declare global {
  interface Window {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    originalAddEventListener: any;
    allHandlers: EventListener[];
    removeEventListeners: (type: string) => void;

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    android: any;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    webkit: any;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    AppBridge: any;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    loginFunction: any; // 👈️ turn off type checking
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    showPopupFindAddress: any;
  }
}

function App() {
  const { accessToken, user } = useSelector((state: RootState) => state.auth);
  const [getMe, { isLoading }] = useLazyGetUserMeQuery();
  const [loading, setLoading] = useState<boolean>(false);

  usePermissions();

  const dispatch = useDispatch<AppDispatch>();

  const handleGetMe = async () => {
    const { data } = (await getMe()) as UseMutationError<GetUserMeApiResponse>;

    if (data?.status === "success") {
      dispatch(updateUserProfile(data?.data ?? null));
    } else {
      if (window) {
        dispatch(api.util.resetApiState());
      }
      dispatch(updateAccessToken(null));
      dispatch(updateUserProfile(null));
      localStorage.clear();
    }
  };
  const routesPrivate = useRoutes(routeConfig.private);

  const routesPublic = useRoutes(routeConfig.public);

  const loadingRef = useRef<unknown | undefined>(undefined);

  useEffect(() => {
    if (accessToken) {
      handleGetMe();
    }
  }, [accessToken]);

  useEffect(() => {
    const start = () => {
      if (loadingRef.current) {
        clearTimeout(loadingRef.current as number);
      }
      loadingRef.current = setTimeout(() => {
        setLoading(true);
      }, 200);
    };
    const end = () => {
      if (loadingRef.current) {
        clearTimeout(loadingRef.current as number);
      }
      setLoading(false);
    };

    window.addEventListener("showLoading", start);
    window.addEventListener("hideLoading", end);
    return () => {
      window.removeEventListener("showLoading", start);
      window.removeEventListener("hideLoading", end);
    };
  }, []);

  return (
    <>
      <SpinBase spinning={loading} />
      <ConfigProvider
        locale={jaJP}
        theme={{
          token: {
            fontFamily: `"Roboto", sans-serif`,
          },
        }}
      >
        <LayoutProvider>
          {isLoading ? (
            <div className="w-full flex justify-center">
              <SpinBase spinning></SpinBase>
            </div>
          ) : user ? (
            // <Suspense fallback={<Spin spinning={true} />}>
            <Suspense fallback={<Loader />}>
              <PrimaryLayout>{routesPrivate}</PrimaryLayout>
            </Suspense>
          ) : (
            // </Suspense>
            <div className="w-full h-[100vh] flex">
              {accessToken ? (
                <div className="w-full flex justify-center">
                  <SpinBase spinning></SpinBase>
                </div>
              ) : (
                <Suspense fallback={<Loader />}>
                  <PublicLayout>{routesPublic}</PublicLayout>
                </Suspense>
              )}
            </div>
          )}
        </LayoutProvider>
        {/* <Routes>
          <Route element={<MainPage />} path="/" />
          <Route element={<CreateProject />} path="/create-project" />
          <Route element={<CreateProject />} path="/create-project/:id" />
          <Route element={<AssetGuarantee />} path="/asset-guarantee" />
          <Route element={<PersonalGuarantee />} path="/personal-guarantee" />
          <Route element={<CashGuarantee />} path="/cash-guarantee" />
          <Route element={<ExportPDF />} path="/export-pdf" />
          <Route element={<TemplatePdf />} path="/template-pdf" />
          <Route element={<ExportPdfExample />} path="/export-pdf-example" />
          <Route element={<PseudoLogin />} path="/pseudo-login" />
          <Route element={<LoginPage />} path="/login" />
        </Routes> */}
      </ConfigProvider>
    </>
  );
}

export default App;
