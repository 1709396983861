import { Button } from "antd";
import { useNavigate } from "react-router-dom";

function NotFound() {
  const navigate = useNavigate();

  return (
    <div className="flex items-center justify-center flex-col min-h-screen space-y-2">
      <h3 className="text-[#222222]">予期せぬエラーが発生しました。</h3>
      <Button
        className="!bg-[#222222] text-white rounded-[4px]"
        onClick={() => {
          navigate("/");
        }}
      >
        TOPページへ
      </Button>
    </div>
  );
}

export default NotFound;
