import React from "react";
import { Image, Layout } from "antd";
import { useNavigate } from "react-router-dom";

// import { MenuOutlined } from "@ant-design/icons";

/* eslint-disable max-lines */
/* eslint-disable max-lines-per-function */
/* eslint-disable react/jsx-sort-props */
/* eslint-disable @typescript-eslint/no-explicit-any */

function PublicLayout({ children }: { children: React.ReactNode }) {
  const navigate = useNavigate();
  return (
    <Layout className="min-h-screen w-[100%]">
      <div className="w-full h-full">
        <div className="bg-[#3594D0] h-[45px] w-full flex items-center justify-between pl-2 pr-4 fixed top-0 left-0 z-[1000]">
          <h1
            className="text-[#F5FBFF] font-bold text-[20px] leading-[29px] uppercase cursor-pointer"
            onClick={() => {
              navigate("/");
            }}
            tabIndex={0}
          >
            <Image preview={false} src={"/logo512.png"} width={30} />
          </h1>
        </div>
        <div className="pt-[46px] w-full h-full">
          <Layout style={{ padding: "0 24px 24px", height: "100%" }}>{children}</Layout>
        </div>
      </div>
    </Layout>
  );
}

export default PublicLayout;
