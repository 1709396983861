import React, { useContext, useEffect, useState } from "react";
import Sider from "antd/es/layout/Sider";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import { LogoutOutlined } from "@ant-design/icons";
import { Image, Layout, Menu, Popover, Tooltip } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { api } from "../../redux/api";
import { usePostLogoutMutation } from "../../redux/endpoints/auth";
import { updateAccessToken, updateUserProfile } from "../../redux/slices/auth.slice";
import { RootState } from "../../redux/store";
import { ROUTER_NAME } from "../../routes/routerName";
import { LayoutContext, LayoutContextInterface } from "../LayoutContext";

// import Logo from "@/public/logo512.png";

// import { MenuOutlined } from "@ant-design/icons";

/* eslint-disable max-lines */
/* eslint-disable max-lines-per-function */
/* eslint-disable no-console */
/* eslint-disable react/jsx-sort-props */
/* eslint-disable @typescript-eslint/no-explicit-any */

function PrimaryLayout({ children }: { children: React.ReactNode }) {
  const { collapsed } = useContext<LayoutContextInterface>(LayoutContext);
  const { user } = useSelector((state: RootState) => state.auth);
  const dispatch = useDispatch();

  const [activeMenu, setActiceMenu] = useState([""]);
  const location = useLocation();
  const navigate = useNavigate();
  const [triggerLogout] = usePostLogoutMutation();

  const handleLogout = () => {
    window?.dispatchEvent(new CustomEvent("showLoading"));
    triggerLogout({})
      ?.unwrap()
      ?.then(() => {
        dispatch(api.util.resetApiState());
        dispatch(updateAccessToken(null));
        dispatch(updateUserProfile(null));
        localStorage.clear();
      });
    window?.dispatchEvent(new CustomEvent("hideLoading"));
  };

  useEffect(() => {
    if (location.pathname == "/login") {
      navigate("/");
    }
  }, []);

  useEffect(() => {
    if (location.pathname?.includes("/project")) {
      setActiceMenu(["item_projects"]);
    } else if (location.pathname?.includes("/authority-management")) {
      setActiceMenu(["item_authority"]);
    } else {
      setActiceMenu([""]);
    }
  }, [location]);

  const svgIcon = (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M4 14H8V10H4V14ZM10 14H14V10H10V14ZM4 8H8V4H4V8ZM10 8H14V4H10V8ZM2 18C1.45 18 0.979167 17.8042 0.5875 17.4125C0.195833 17.0208 0 16.55 0 16V2C0 1.45 0.195833 0.979167 0.5875 0.5875C0.979167 0.195833 1.45 0 2 0H16C16.55 0 17.0208 0.195833 17.4125 0.5875C17.8042 0.979167 18 1.45 18 2V16C18 16.55 17.8042 17.0208 17.4125 17.4125C17.0208 17.8042 16.55 18 16 18H2ZM2 16H16V2H2V16Z"
        fill="black"
      />
    </svg>
  );

  const menuItems: any = [
    {
      key: "item_projects",
      icon: svgIcon,
      label: "案件一覧",
      onClick: () => {
        navigate("/project/?pageSize=10&pageCurrent=1");
      },
    },

    {
      key: "item_authority",
      icon: svgIcon,
      label: "権限管理",
      onClick: () => {
        navigate(ROUTER_NAME.AUTHORITY_MANAGEMENT);
      },
    },

    {
      key: "grp_others",
      label: "その他",
      type: "group",
      children: [
        {
          key: "item_inquiry",
          icon: svgIcon,
          label: "問い合わせ",
          onClick: () => {
            navigate("/inquiry");
          },
        },
      ],
      authority: ["GENERAL_MANAGER", "STAFF"],
    },
  ];

  const menuItemsRenderGroup = menuItems?.filter((e: any) => !e.authority || e.authority?.includes(user?.role));
  const menuItemsRenderChildren = menuItemsRenderGroup?.map((e: any) => {
    if (!e.children) {
      return e;
    }
    if (e.children) {
      const newChildren = e.children?.filter((ele: any) => !ele.authority || ele.authority?.includes(user?.role));
      return { ...e, children: newChildren };
    }
  });
  const { height } = useWindowDimensions();

  return (
    <Layout className="min-h-screen w-[100%]">
      <div className="w-full h-full">
        <div className="bg-[#3594D0] h-[45px] w-full flex items-center justify-between pl-2 pr-4 fixed top-0 left-0 z-[1000]">
          <div
            className="text-[#F5FBFF] cursor-pointer"
            onClick={() => {
              navigate("/");
            }}
            tabIndex={0}
          >
            <Image preview={false} src={"/logo512.png"} width={30} />
          </div>
          <div
            className="cursor-pointer text-[#fff]"
            onClick={() => {
              handleLogout();
            }}
            tabIndex={0}
          >
            <Tooltip placement="left" title={"ログアウト"}>
              <LogoutOutlined />
            </Tooltip>
          </div>
        </div>
        <div className="mt-[66px] w-full h-full">
          <Sider
            className="!bg-white border-r"
            style={{ overflow: "auto", height: "100vh", position: "fixed", left: 0, top: 46, bottom: 0, zIndex: 99 }}
            collapsed={collapsed}
            collapsible
            trigger={null}
          >
            <div style={{ height: height - 106, overflow: "auto" }}>
              <Menu
                className="[&_.ant-menu-item]:!ps-[20px] [&_.ant-menu-item]:flex [&_.ant-menu-item]:items-center "
                selectedKeys={activeMenu}
                items={menuItemsRenderChildren}
                style={{ borderRight: 0 }}
              />
            </div>
            <div className="absolute bottom-[46px] bg-white w-full shadow-inner">
              <Popover
                placement="top"
                content={
                  <div className="w-[200px]">
                    <p
                      className="p-[6px] font-bold cursor-pointer hover:bg-[#F4F6F7]"
                      onClick={() => {
                        navigate("/change-password");
                      }}
                    >
                      パスワードの更新
                    </p>
                    <p className="p-[6px] font-bold cursor-pointer hover:bg-[#F4F6F7]" onClick={handleLogout}>
                      ログアウト
                    </p>
                  </div>
                }
                arrow={false}
              >
                <div className="flex ps-[23px] my-[12px] relative cursor-pointer  items-center space-x-[10px] ">
                  <div>{svgIcon}</div>
                  {!collapsed && <div className="font-bold text-[#2f2f2f]">{user?.name}</div>}
                  <div className="absolute right-[16px]">
                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <rect
                        x="19.5"
                        y="19.5"
                        width="19"
                        height="19"
                        rx="2.5"
                        transform="rotate(-180 19.5 19.5)"
                        fill="white"
                        stroke="#DFE5EE"
                      />
                      <path
                        d="M9.28926 6.71811C9.6806 6.32271 10.3194 6.32271 10.7107 6.71811L14.3762 10.4215C15.0012 11.053 14.5539 12.125 13.6655 12.125L6.33455 12.125C5.44612 12.125 4.99884 11.053 5.6238 10.4215L9.28926 6.71811Z"
                        fill="#80888F"
                      />
                    </svg>
                  </div>
                </div>
              </Popover>
            </div>
          </Sider>

          <Layout style={{ padding: "0 24px 24px", height: "100%", marginLeft: collapsed ? 80 : 200 }}>
            {children}
          </Layout>
        </div>
      </div>
    </Layout>
  );
}

export default PrimaryLayout;
