import NotFound from "../../pages/NotFound";
import { lazy } from "react";
import { Navigate, NonIndexRouteObject, RouteObject } from "react-router-dom";

const ProjectPage = lazy(() => import("../../pages/project"));
const DetailProjectPage = lazy(() => import("../../pages/project/detail"));
const LoginPage = lazy(() => import("../../pages/login"));
const RedirectPage = lazy(() => import("../../pages/redirectPage"));
const ForgotPasswordPage = lazy(() => import("../../pages/forgot-password"));
const ResetPasswordPage = lazy(() => import("../../pages/reset-password"));
const AuthorityManagement = lazy(() => import("../../pages/authority-management"));
const AuthorityCreate = lazy(() => import("../../pages/authority-management/create"));
const AuthorityEdit = lazy(() => import("../../pages/authority-management/edit"));
const ChangePassword = lazy(() => import("../../pages/change-password"));
interface PrivateConfig extends NonIndexRouteObject {
  authority?: string[];
}
interface TypeRouteConfig {
  private: PrivateConfig[];
  public: RouteObject[];
}
export const routeConfig: TypeRouteConfig = {
  private: [
    {
      path: "/",
      element: <Navigate to="/project/?pageSize=10&pageCurrent=1" />,
    },
    {
      path: "/project",
      element: <ProjectPage />,
    },
    {
      path: "/project/:id",
      element: <DetailProjectPage />,
    },
    {
      path: "authority-management",
      element: <AuthorityManagement />,
    },
    {
      path: "authority-management/create",
      element: <AuthorityCreate />,
    },
    {
      path: "authority-management/edit/:id",
      element: <AuthorityEdit />,
    },
    {
      path: "/change-password",
      element: <ChangePassword />,
    },
    {
      path: "*",
      element: <NotFound />,
    },
  ],
  public: [
    { path: "/login", element: <LoginPage /> },
    { path: "/forgot-password", element: <ForgotPasswordPage /> },
    { path: "/reset-password", element: <ResetPasswordPage /> },
    {
      path: "*",
      element: <RedirectPage />,
    },
  ],
};
